/* CSS File for projects page */

/* Projects Section */
.projects {
  display: inline-flex;
  align-items: center;
  margin: 0 5%;
  height: 70vh;
}

.projects img {
  width: 35%;
}

/* Left Project */
.left-section {
  width: 30%;
  animation: fade-in 2s ease-in;
}

.left-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.left-container img {
  width: 40%;
  padding: 5px;
  border: 2px solid var(--grey-color);
  border-radius: 10px;
  transition: transform ease-in-out 1.5s;
}

.left-container img:hover {
  transform: scale(1.5);
}

.left-container h3 {
  margin: 10px 0;
  border-bottom: 2px solid var(--bright-color);
}

.left-container span {
  color: var(--bright-color);
}

.left-container p {
  text-align: center;
  margin-top: 5px;
}

.left-container .leftIcon {
  font-size: 12px;
  margin-left: 10px;
  color: var(--grey-color);
}

.left-container .leftIcon:hover {
  color: var(--bright-color);
}

/* Middle Header */
.middle-section {
  width: 20%;
  height: 100vh;
  animation: fade-in 3s ease-in;
}

.middle-section h2 {
  margin-top: 100%;
  transform: rotate(270deg);
  font-size: 80px;
  color: var(--dark-color);
  text-shadow: -1px 0 var(--bright-color), 0 1px var(--bright-color),
    1px 0 var(--bright-color), 0 -1px var(--bright-color);
}

/* Right Project Section */
.right-section {
  width: 60%;
  margin: 0 40px;
  animation: fade-in 4s ease-in;
}

.right-container {
  position: relative;
}

.inner-project-container {
  display: flex;
}

.individual-project {
  text-align: center;
}

.individual-project h3 {
  padding: 2px;
  width: 50%;
  border-bottom: 2px solid var(--bright-color);
}

.individual-project p {
  padding: 0 30px;
}

.individual-project img {
  margin: 20px 30px;
  width: 80%;
  border-radius: 5px;
}

/* Animation */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* Test carosel */

.project {
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.4s ease-in-out;
}

.project.active {
  opacity: 1;
}

.carousel-prev,
.carousel-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  border: none;
}

.carousel-prev {
  left: -10px;
}

.carousel-next {
  right: -10px;
}

.carousel-prev:hover,
.carousel-next:hover {
  color: var(--bright-color);
}

.carousel-next:active,
.carousel-prev:active {
  animation: bounce 0.5s ease-in-out;
}

@keyframes bounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-25%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}

/* Media Queries */
@media (max-width: 1200px) {
  .projects {
    display: block;
    margin: 0;
    height: auto;
  }

  .projects h3 {
    animation: fade-in 2s ease-in;
  }

  .projects p {
    font-size: 16px;
    animation: fade-in 3s ease-in;
  }

  .projects img {
    width: 50%;
    margin: 10px 0;
    animation: fade-in 1s ease-in;
  }

  .left-container p {
    margin: 10px 40px;
  }

  .middle-section {
    height: fit-content;
  }

  .middle-section h2 {
    transform: rotate(0deg);
    margin: 0 20px;
    animation: fade-in 4s ease-in;
  }

  .right-section {
    margin: 0;
    margin-top: 200px;
    margin-bottom: 200px;
  }

  .left-section,
  .middle-section {
    width: fit-content;
    margin: 30px 0;
  }

  .right-section {
    width: 100%;
  }
  .right-container {
    margin: 0 30px;
  }

  .inner-project-container {
    flex-direction: column;
  }

  .individual-project h3 {
    width: 70%;
    margin-bottom: 10px;
  }

  .project {
    margin-bottom: 20px;
  }

  .mobile-project h3 {
    width: 80%;
    margin-bottom: 20px;
  }

  .mobile-project img {
    display: none;
  }

  .mobile-project-inner {
    flex-direction: column;
  }
}
