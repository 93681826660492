/* CSS for Contact Page */

/* Contact Body */
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Form */
.form {
  position: relative;
  display: flex;
  align-items: center;
}

.form .left-form,
.form .right-form {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.form label {
  font-size: 60px;
  text-transform: uppercase;
  color: var(--dark-color);
  text-shadow: -1px 0 var(--grey-color), 0 1px var(--grey-color),
    1px 0 var(--grey-color), 0 -1px var(--grey-color);
}

.form input {
  margin-bottom: 10px;
  border-radius: 20px;
  padding: 5px;
  border: none;
}

.form textarea {
  border-radius: 10px;
  height: 150px;
  width: 300px;
  padding: 5px;
  margin-bottom: 30px;
}

.form button {
  position: absolute;
  bottom: 30px;
  align-self: center;
  cursor: pointer;
  background-color: var(--bright-color);
  color: var(--dark-color);
  padding: 10px;
  width: 150px;
  border: none;
  border-radius: 20px;
}

.form button:hover {
  transform: scale(1.03);
}

.form button:active {
  transform: scale 0.97;
}

.form input:focus,
.form textarea:focus {
  outline: 2px solid var(--bright-color);
}

/* Email Address */
.email {
  font-size: 60px;
  color: var(--dark-color);
  text-shadow: -1px 0 var(--grey-color), 0 1px var(--grey-color),
    1px 0 var(--grey-color), 0 -1px var(--grey-color);
  transition: all 1s ease-in-out;
}

.email:hover {
  font-size: 60px;
  text-transform: uppercase;
  color: var(--dark-color);
  text-shadow: -1px 0 var(--bright-color), 0 1px var(--bright-color),
    1px 0 var(--bright-color), 0 -1px var(--bright-color);
}

@media (max-width: 1200px) {
  .contact {
    animation: fade-in 1.5s ease-in;
  }

  .form {
    flex-direction: column;
  }

  .form label {
    text-align: left;
  }
}
