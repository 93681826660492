.individual-project {
  text-align: center;
}

.individual-project h3 {
  width: 50%;
  border-bottom: 2px solid var(--bright-color);
}

.individual-project p {
  padding: 0 30px;
}

.individual-project .rightIcon,
.mobile-project .rightIcon {
  font-size: 12px;
  margin: 3px;
  color: var(--grey-color);
}

.individual-project .rightIcon:hover,
.mobile-project .rightIcon:hover {
  color: var(--bright-color);
}

/* Mobile Projects */
.mobile-project h3 {
  width: 50%;
  text-align: center;
  border-bottom: 2px solid var(--bright-color);
}

.mobile-project {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.mobile-project img {
  width: 10%;
}

.mobile-project p {
  text-align: center;
  padding: 0 20px;
}

/* Animation */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
